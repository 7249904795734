import { useRef, useState } from "react";
import { useFrame } from '@react-three/fiber';
import { useEffect } from "react";
import { useTexture } from "@react-three/drei";

function Box({ color, rotationSpeed = 0.002, ...props }) {
    // This reference will give us direct access to the mesh
    const mesh = useRef();
    const texture = useTexture({map: "/cross.jpg"});
    const [initialPosition, setInitialPosition] = useState(props.position);
    // Subscribe this component to the render-loop, rotate the mesh every frame
    useFrame((state, delta) => (mesh.current.rotation.y += rotationSpeed))//  document.pageYOffset / 100))
    // Return view, these are regular three.js elements expressed in JSX

    useEffect(() => {
        document.querySelector('#root').addEventListener('scroll', (event) => {
            mesh.current.rotation.x = event.target.scrollTop / 500;
            mesh.current.position.z = event.target.scrollTop / 250 + initialPosition[2];
        })
    }, []);


    return (
        <mesh
            {...props}
            ref={mesh}
        >
            {/* <boxGeometry args={[1, 1, 1]} /> */}
            <sphereGeometry args={[0.5, 48, 48]} />
            <meshStandardMaterial
                color={color}
                roughness={0}
                envMapIntensity={0.8}
                emissive="#000"
                {...texture}
            />
        </mesh>
    )
}

export default Box;