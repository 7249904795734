import './styles.scss';
import './mdb.min.scss';

import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { useNavigate } from 'react-router-dom';

import { MDBFooter } from 'mdb-react-ui-kit';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
// import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import Pulse from 'react-reveal/Pulse';


const Footer = () => {

    const navigate_ = useNavigate();

    const scrollToTop = () => {
        document.querySelector('#root').scrollTo({ top: 0, behavior: 'smooth' });
    }

    const navigate = path => {
        scrollToTop();
        navigate_(path);
    }

    return (
        <Zoom bottom>
            {/* // <div className='footer blurry-bg'>
        //     <div
        //         className='floating-arrow'
        //         onClick={scrollToTop}
        //     >
        //         <KeyboardArrowUpOutlinedIcon
        //             color='secondary'
        //             className='arrow-icon'
        //             fontSize='large'
        //         />
        //     </div>
        //     <div className='container'>
        //         <p>
        //             © 2021 Redlionandcompany, All rights reserved.
        //         </p>
        //         <div className='nav-container'>
        //             <li className="navbar-list"><a className="cool-link" onClick={() => navigate('/')}>Home</a></li>
        //             <li className="navbar-list"><a className="cool-link" onClick={() => navigate('/predict-facebook-compaign')}>Meta Marketing</a></li>
        //             <li className="navbar-list"><a className="cool-link" onClick={() => navigate('/profile')}>Account</a></li>
        //         </div>
        //     </div>
        // </div> */}
            <footer style={{ marginTop: 20 }} className='use-mdb text-center text-lg-start text-muted blurry-bg'>
                <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
                    <div className='me-5 d-none d-lg-block'>
                        <span>Get connected with us on social networks:</span>
                    </div>

                    <div>
                        <a href='' className='me-4 text-reset'>
                            <i className='fab fa-facebook-f'></i>
                        </a>
                        <a href='' className='me-4 text-reset'>
                            <i className='fab fa-twitter'></i>
                        </a>
                        <a href='' className='me-4 text-reset'>
                            <i className='fab fa-instagram'></i>
                        </a>
                        <a href='https://www.linkedin.com/company/red-lion-and-company/mycompany/' className='me-4 text-reset'>
                            <i className='fab fa-linkedin'></i>
                        </a>
                    </div>
                </section>

                <section className=''>
                    <div className='container text-center text-md-start mt-5'>
                        <div className='row mt-3'>
                            <div className='col-md-3 col-lg-4 col-xl-3 mx-auto mb-4'>
                                <h6 className='text-uppercase fw-bold mb-4'>
                                    RedLion Matrix
                                </h6>
                                <p>
                                    Red Lion Marketing is a Google and Facebook certified Agency and has been founded in 2016. Our teams have over 10 years experience in conducting Digital Marketing projects.
                                </p>
                            </div>

                            <div className='col-md-2 col-lg-2 col-xl-2 mx-auto mb-4'>
                                {/* <h6 className='text-uppercase fw-bold mb-4'>Products</h6>
                            <p>
                                <a href='#!' className='text-reset'>
                                    Angular
                                </a>
                            </p>
                            <p>
                                <a href='#!' className='text-reset'>
                                    React
                                </a>
                            </p>
                            <p>
                                <a href='#!' className='text-reset'>
                                    Vue
                                </a>
                            </p>
                            <p>
                                <a href='#!' className='text-reset'>
                                    Laravel
                                </a>
                            </p> */}
                            </div>

                            <div className='col-md-3 col-lg-2 col-xl-2 mx-auto mb-4 useful-links'>
                                <h6 className='text-uppercase fw-bold mb-4'>Useful links</h6>
                                <p>
                                    <a href='/' className='cool-link text-reset cool'>
                                        Home
                                    </a>
                                </p>
                                <p>
                                    <a onClick={() => navigate('/predict-facebook-compaign')} className='cool-link text-reset'>
                                        Meta Marketing
                                    </a>
                                </p>
                                <p>
                                    <a onClick={() => navigate('/profile')} className='cool-link text-reset'>
                                        Account
                                    </a>
                                </p>
                                <p>
                                    <a onClick={() => navigate('/settings')} className='cool-link text-reset'>
                                        Settings
                                    </a>
                                </p>
                            </div>

                            <div className='col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4'>
                                <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
                                <p>
                                    <i className='fas fa-home me-3'></i>St. Madinat el Anbar, Sidi Fradj, Soukra, Tunisia.
                                </p>
                                <p>
                                    <i className='fas fa-envelope me-3'></i>
                                    supoort@redlionmatrix.tech
                                </p>
                                <p>
                                    <i className='fas fa-phone me-3'></i> + 216 21 576 834
                                </p>
                                <p>
                                    <i className='fas fa-phone me-3'></i> + 44 7 452 307 774
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
                    © 2021 Copyright: &nbsp;
                    <a className='text-reset fw-bold' href='https://redlionmatrix.tech/'>
                        redlionmatrix.tech
                    </a>
                </div>
            </footer>
        </Zoom>
    );
}

export default Footer;