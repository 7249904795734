import './styles.scss';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { useEffect, useRef } from 'react';
import { Button } from '@mui/material';
import Icons from '../../shared/assets/icons';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import TypeWriterEffect from 'react-typewriter-effect';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import useIsInViewport from '../../shared/hooks/useInViewport';
import { RandomBoxes } from '../../shared/components';



const HomePage = () => {
    const contentRef = useRef(null);
    const videoRef = useRef(null);
    const videoContainerRef = useRef(null);
    const welcomeRef = useRef(null);
    const isVideoInViewport = useIsInViewport(videoRef);
    const navigate = useNavigate();
    const [height, setHeight] = useState(document.body.clientHeight);

    const scrollToContent = () => {
        contentRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };

    const scrollToVideo = () => {
        videoContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const scrollToTop = () => {
        welcomeRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    useEffect(() => {
        window.addEventListener('resize', () => {
            setHeight(document.body.clientHeight)
        });
    }, []);

    useEffect(() => {
        if (isVideoInViewport) {
            videoRef.current.play();
        }
        else {
            videoRef.current.pause();
        }
    }, [isVideoInViewport]);

    return (
        <div className="home-container" ref={welcomeRef}>
            <Fade top>
                <div className='header' style={{ height }}>
                    <TypeWriterEffect
                        startDelay={50}
                        text="REDLION MATRIX"
                        typeSpeed={85}
                        hideCursorAfterText
                    />

                    <h2 className='glow' >
                        DIGITAL MARKETING INTELLIGENCE
                    </h2>
                    <div
                        className='floating-arrow'
                        onClick={scrollToVideo}
                    >
                        <KeyboardArrowDownOutlinedIcon
                            color='secondary'
                            fontSize='inherit'
                            className='arrow-icon'
                        />
                    </div>
                </div>
            </Fade>

            <div className='video-container' ref={videoContainerRef} style={{ height }} >
                <Fade left>
                    <div className='video-container'>
                        <video ref={videoRef} controls >
                            <source src="https://redlionmatrix.tech/wp-content/uploads/2022/01/matrixxx.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </Fade>


                <div
                    className='floating-arrow'
                    onClick={scrollToContent}
                >
                    <KeyboardArrowDownOutlinedIcon
                        color='secondary'
                        fontSize='inherit'
                        className='arrow-icon'
                    />
                </div>
            </div>


            <div className='content blurry-bg' ref={contentRef} >
                <Zoom>
                    <div className="ai-card active" onClick={() => navigate('/predict-facebook-compaign')} >
                        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                            <img width={height / 6} height={height / 6} src={Icons.MetaLogo} alt="meta logo" />
                        </div>
                        <p>
                            The Meta Model is an AI solution that aims to assist
                            companies in choosing their Meta Digital Marketing Strategy
                            wisely and effectively.
                        </p>
                        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                            <Button
                                color='secondary'
                                className='trynow-btn'
                                sx={{
                                    fontSize: '2rem',
                                }}
                                variant='contained'
                            >
                                Try now
                            </Button>
                        </div>
                    </div>
                </Zoom>
                <Zoom>
                    <div className="ai-card">
                        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                            <img width={height / 6} height={height / 6} src={Icons.GoogleLogo} alt="google logo" />
                        </div>
                        <p>
                            The Google Model is an AI solution that aims to assist
                            companies in choosing their Meta Digital Marketing Strategy
                            wisely and effectively.
                        </p>
                        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                            <Button
                                color='secondary'
                                size='large'
                                // className='trynow-btn'
                                sx={{
                                    fontSize: '2rem',
                                }}
                                onClick={() => navigate('/predict-google-compaign')}
                                disabled
                            >
                                Coming Soon
                            </Button>
                        </div>

                    </div>
                </Zoom>
            </div>
        </div>
    );
}

export default HomePage;