import { Button, FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput } from "@mui/material"
import { useEffect, useState } from "react";
import { CustomDatePicker, SelectInput } from "../../shared/components";
import { addDays } from 'date-fns';
import data from "./Data";
import './styles.scss';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { formatDateToApi } from "../../shared/helpers/helpers";
import { toast } from "react-toastify";
import { BASE_URL } from "../../services/api.service";
import { predictMeta } from "../../services/meta.service";
import Fade from 'react-reveal/Fade';
import Icons from "../../shared/assets/icons";
import TypeWriterEffect from 'react-typewriter-effect';


const FbAiFormPage = () => {
    const navigate = useNavigate();

    const [form, setForm] = useState(data[0]);
    const [formIndex, setFormIndex] = useState(0);
    const [oldIndex, setOldIndex] = useState(0);
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(false);
    const [counter, setCounter] = useState(0);

    useEffect(() => {
        setForm(data[formIndex]);
    }, [formIndex]);

    const handleChange = (event) => {
        incrementCounter();
        setFormData({ ...formData, [form.key]: event.target.value });
    };

    const handleNext = () => {
        if (formIndex < data.length - 1) {
            incrementCounter();
            setOldIndex(formIndex);
            setFormIndex(formIndex + 1);
        }
    }

    const handleBack = () => {
        if (formIndex > 0) {
            incrementCounter();
            setOldIndex(formIndex);
            setFormIndex(formIndex - 1);
        }
    }

    const incrementCounter = () => {
        setCounter(counter + 1);
    }

    const findLabelByValue = (value) => {
        const label = [
            ...data[0].options,
            ...data[1].options,
        ].find(option => option.value === value)?.label;
        return label;
    }

    const findDescriptionByValue = (value) => {
        const description = [
            ...data[0].options,
            ...data[1].options,
        ].find(option => option.value === value)?.description;
        return description;
    }

    const handleSubmit = async () => {
        setLoading(true);

        const dataToSend = {
            "sector": formData.sector,
            "objective": formData.goal,
            "amount": formData.budget,
            "start_date": formatDateToApi(formData.dateRange[0].startDate),
            "end_date": formatDateToApi(formData.dateRange[0].endDate),
        }
        try {
            const data = await predictMeta(dataToSend);

            for (let key in data) {
                if (Array.isArray(data[key])) {
                    for (let index = 0; index < data[key].length; index++) {
                        data[key][index] = Math.floor(data[key][index]);
                    }
                }
            }
            setLoading(false);
            navigate('/statistics', { state: { ...data, goal: findLabelByValue(formData.goal) } });
            console.log(data);
        } catch (err) {
            setLoading(false);
            toast.error(err?.response?.data?.message || 'Something wrong has occured, Try again later!')
            console.log(err);
        }

    }

    return (

        <div className="Form-content">
            <Fade left={oldIndex < formIndex} right={oldIndex > formIndex} spy={formIndex}>
                <div className="Form-container">
                    <h1 className="glow" style={{ textAlign: 'center', flex: 1 }}>{form.title}</h1>
                    <div style={{ flex: 1, width: "100%" }}>
                        {
                            form.dateRange ?
                                <CustomDatePicker
                                    ranges={formData[form.key] || [{
                                        startDate: new Date(),
                                        endDate: addDays(new Date(), 7),
                                        key: 'selection',
                                        color: '#f00'
                                    }]}
                                    onChange={val => setFormData({ ...formData, [form.key]: val })}
                                    label="Start Date"
                                    color="secondary"
                                    handleBack={handleBack}
                                    loading={loading}
                                    handleSubmit={handleSubmit}
                                    disabled={!formData[form.key]}
                                />

                                : form.type === 'number' ?
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-amount">{form.label}</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-amount"
                                            type="number"
                                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                            label={form.label}
                                            onChange={handleChange}
                                            value={formData[form.key] || form.min}
                                        />
                                        {
                                            formData[form.key] < form.min &&
                                            <FormHelperText error id="component-error-text">The minimum budget is ${form.min}.</FormHelperText>
                                        }

                                    </FormControl>
                                    :
                                    <SelectInput
                                        value={formData[form.key] || ''}
                                        onChange={handleChange}
                                        options={form.options}
                                        label={form.label}
                                    />
                        }

                    </div>

                    <div style={{
                        width: '100%',
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: 'flex-end',
                        flex: 1
                    }}>
                        {
                            !form.dateRange &&
                            <>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleBack}
                                    disabled={formIndex === 0}
                                    className="Form-button"
                                >
                                    Back
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleNext}
                                    disabled={
                                        formIndex === data.length - 1 ||
                                        !formData[form.key] ||
                                        (form.type === 'number' && formData[form.key] < form.min)
                                    }
                                    className="Form-button"
                                >
                                    Next
                                </Button>
                            </>
                        }
                    </div>
                </div>
            </Fade>
            {
                formIndex != 3 &&
                <Fade bottom when={formIndex == 1 && formData.goal} spy={counter}>
                    <div className="Goal-container">
                        <h1 style={{ textAlign: "center" }}>{findLabelByValue(formData.goal)}</h1>
                        <p>{findDescriptionByValue(formData.goal)}</p>
                        <img
                            src={Icons[findLabelByValue(formData.goal)?.replace(' ', '')]}
                            style={{
                                zIndex: -1,
                                maxWidth: '65%',
                                height: 'auto'
                            }}
                            alt="icon"
                        />
                    </div>
                </Fade>
            }
        </div>
    );
}

export default FbAiFormPage;