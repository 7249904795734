import { ThemeProvider } from '@mui/material/styles';
import { useContext, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import MainNavigator from './MainNavigator';
import DefaultTheme from '../shared/themes/DefaultTheme';
import { Navbar, Matrix, Footer, RandomBoxes } from '../shared/components';
import { GlobalContext } from '../context/GlobalProvider';
import { LoadingPage } from '../pages';


const GlobalNavigator = () => {
    const {
        authState: {
            isAuthenticated,
            loading
        }
    } = useContext(GlobalContext);
    const [height, setHeight] = useState(document.body.clientHeight);
    useEffect(() => {
        window.addEventListener('resize', () => {
            setHeight(document.body.clientHeight)
        });
    }, []);

    return (
        <ThemeProvider theme={DefaultTheme}>
            {
                !loading &&
                <Matrix
                    fullscreen
                    color="red"
                    background="#04040D"
                />
            }

            {
                !loading &&
                <RandomBoxes
                    style={{ height, position: 'absolute', zIndex: -1, opacity: 1}}
                    count={25}
                />
            }

            <BrowserRouter basename={process.env.PUBLIC_URL}>
                {
                    isAuthenticated &&
                    <Navbar />
                }
                <div id="main">
                    <LoadingPage />
                    {loading || < MainNavigator />}
                </div>
                <Footer />
            </BrowserRouter>
        </ThemeProvider >
    );
}

export default GlobalNavigator;