import { Formik } from 'formik';
import * as yup from 'yup'
import { CustomButton, CustomInput } from '../../shared/components';
import './styles.scss';
import { AiOutlineUser, AiOutlineMail } from "react-icons/ai";
import { useContext, useState } from 'react';
import { GlobalContext } from '../../context/GlobalProvider';
import { toast } from 'react-toastify';
import { updatePassword } from '../../services/auth.service';
import { toastNetworkError } from '../../shared/helpers/toast-netword-error';


const SettingsPage = () => {
    const {
        authState: {
            user
        },
        authDispatch
    } = useContext(GlobalContext);
    const [disabled, setDisabled] = useState(false);

    const onSubmit = async (values) => {
        toast.dismiss();
        const id = toast.loading('Loading...');
        try {
            setDisabled(true);
            await updatePassword({
                oldPassword: values.oldPassword,
                newPassword: values.newPassword
            });
            
            toast.success('Password updated successfully!');
        } catch (error) {
            console.log(error);
            toastNetworkError(error);
        }
        setDisabled(false);
        toast.dismiss(id);
    }

    return (
        <div style={{ height: '100vh', display: 'flex', alignItems: 'center' }}>
            <div className='settings-form'>
                <h1 style={{textAlign: 'center'}}>Update Your Password</h1>
                <Formik
                    validationSchema={changePasswordValidationSchema}
                    initialValues={{
                        oldPassword: '',
                        newPassword: '',
                        confirmPassword: ''
                    }}
                    onSubmit={onSubmit}
                >
                    {({
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        values,
                        errors,
                        isValid,
                    }) => (
                        <>
                            <CustomInput
                                name="oldPassword"
                                placeholder="Type your current password"
                                onChange={handleChange('oldPassword')}
                                onBlur={handleBlur('oldPassword')}
                                value={values.oldPassword}
                                error={errors.oldPassword}
                                Icon={AiOutlineUser}
                                type="password"
                            />
                            <div className='seperator' />
                            <CustomInput
                                name="newPassword"
                                placeholder="Type your new password"
                                onChange={handleChange('newPassword')}
                                onBlur={handleBlur('newPassword')}
                                value={values.newPassword}
                                error={errors.newPassword}
                                Icon={AiOutlineUser}
                                type="password"
                            />
                            <CustomInput
                                name="confirmPassword"
                                placeholder="Confirm your password"
                                onChange={handleChange('confirmPassword')}
                                onBlur={handleBlur('confirmPassword')}
                                value={values.confirmPassword}
                                error={errors.confirmPassword}
                                Icon={AiOutlineMail}
                                type="password"
                            />
                            <div style={{ height: '20px' }} />
                            <CustomButton
                                onClick={handleSubmit}
                                title="Save Changes"
                                style={{
                                    opacity: isValid && !disabled ? 1 : 0.5,
                                    cursor: isValid && !disabled ? 'pointer' : 'default'
                                }}
                                type="Submit"
                                disabled={!isValid || disabled}
                            />
                            <div style={{ height: '15px' }} />
                        </>
                    )}
                </Formik>
            </div>
        </div>
    )
}

const changePasswordValidationSchema = yup.object().shape({
    oldPassword: yup
        .string()
        .required('Your old password is required'),
    newPassword: yup
        .string()
        .min(8, ({ min }) => `Password must be at least ${min} characters`)
        .required('Password is required'),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
        .required('Confirm Password is required'),
});


export default SettingsPage;