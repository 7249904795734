import { Canvas } from '@react-three/fiber';
import {  Environment } from "@react-three/drei";
import { useEffect, useState } from 'react';
import Box from '../Box/Box';

const COLORS = [
    '#900',
    '#688'
]

function RandomBoxes({ count, ...rest }) {
    const [positions, setPositions] = useState([]);
    const [rotations, setRotations] = useState([]);
    const [rotationSpeed, setRotationSpeed] = useState([]);
    const [colors, setColors] = useState([]);

    const generateRandomVector3 = (start, end) => {
        const x = Math.random() * (end[0] - start[0]) + start[0];
        const y = Math.random() * (end[1] - start[1]) + start[1];
        const z = Math.random() * (end[2] - start[2]) + start[2];
        return [x, y, z];
    }

    const isCollided = (position, positions) => {
        return positions.some(p => {
            const distance = Math.sqrt(Math.pow(p[0] - position[0], 2) + Math.pow(p[1] - position[1], 2) + Math.pow(p[2] - position[2], 2));
            return distance < 2;
        });
    }

    useEffect(() => {
        const positions = [];
        const rotations = [];
        const colors = [];
        const rotationSpeed = [];
        for (let i = 0; i < count; i++) {
            let position = generateRandomVector3([-10, -5, -10], [10, 5, 0]);
            while (isCollided(position, positions)) {
                position = generateRandomVector3([-10, -5, -10], [10, 5, 0]);
            }
            positions.push(position);
            rotations.push(generateRandomVector3([0, 0, 0], [Math.PI, Math.PI, Math.PI]));
            colors.push(COLORS[Math.floor(Math.random() * COLORS.length)]);
            rotationSpeed.push(Math.random() * 0.006 - 0.003);
        }
        setPositions(positions);
        setRotations(rotations);
        setColors(colors);
        setRotationSpeed(rotationSpeed);
    }, []);

    return (
        <Canvas
            {...rest}
        >
            <ambientLight />
            {/* <spotLight intensity={1} angle={0.2} penumbra={1} position={[30, 30, 30]} castShadow shadow-mapSize={[512, 512]} /> */}
            <pointLight position={[10, 10, 10]} />
            {Array.from({ length: count }, (_, i) => (
                <Box
                    key={i}
                    position={positions[i]}
                    rotation={rotations[i]}
                    color={colors[i]}
                    rotationSpeed={rotationSpeed[i]}
                />
            ))}
            <Environment files="/adamsbridge.hdr" />
        </Canvas>
    );
}

export default RandomBoxes;