import './styles.scss';
import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { Card, CustomCircularProgressbar } from '../../shared/components';
import Icons from '../../shared/assets/icons';
import dnaImage from '../../shared/assets/images/dna.png';
import graphImage from '../../shared/assets/images/graph.png';
import { config, useSpring, animated } from 'react-spring';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import TypeWriterEffect from 'react-typewriter-effect';


const StatisticsPage = () => {
    const location = useLocation();

    const data = location.state;

    const [diameter, setDiameter] = useState(Math.min(window.innerHeight * 0.6, window.innerWidth * 0.8));

    useEffect(() => {
        window.addEventListener('resize', () => {
            setDiameter(Math.min(window.innerHeight * 0.6, window.innerWidth * 0.8));
        })
    }, [])

    console.log(data);

    const AnimateNumber = num => {
        const { number } = useSpring({
            from: { number: 0 },
            number: num,
            delay: 200,
            config: config.molasses,
        });
        return number.to(num => num.toFixed(0));
    }

    return (
        <div className="Statistics-container">
            <div className="Section1">
                {/* <Zoom>
                    <Card title="Reach" backgroundColor="#1c1839" style={{ marginBottom: 10 }}>
                        <h4>Expected Results Between</h4>
                        <h2>{data?.reach_margins?.join(', ')}</h2>
                        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                            <img src={dnaImage} />
                        </div>
                    </Card>
                </Zoom> */}
                <Fade left>
                    <Card className="blurry-bg" title="Quick Reminder" style={{ gridRowStart: 1 }}>
                        <p>Your Sector is: <strong>{data?.sector} </strong></p>
                        <p>Your Goal is: <strong>{data?.goal}</strong></p>
                        <p>Your Budget is: <strong>${data?.amount}</strong></p>
                        <p>
                            Your Compaign will be from	&nbsp;
                            <strong>{data?.start_date}</strong> 	&nbsp;
                            to 	&nbsp;
                            <strong>{data?.end_date}</strong>.
                        </p>

                    </Card>
                </Fade>
            </div>
            <div className="Section2">
                <div style={{ marginBottom: 40 }}>
                    <TypeWriterEffect
                        startDelay={50}
                        cursorColor="black"
                        text="Prediciton Summary"
                        typeSpeed={85}
                        textStyle={{ fontSize: "xxx-large", textAlign: "center", padding: 10 }}
                    />
                </div>
                <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-around", width: "100%" }}>
                    <div id="info">
                        <img width="35px" height="35px" className='predictions-icons' src={Icons.Reach} />
                        <div style={{ fontSize: 'small' }}>Reach</div>
                        <div>
                            <animated.span>
                                {AnimateNumber(data?.reach_margins[0])}
                            </animated.span>,
                            <animated.span>
                                {AnimateNumber(data?.reach_margins[1])}
                            </animated.span>
                        </div>
                    </div>

                    <CustomCircularProgressbar
                        width={diameter}
                        strokeWidth={3}
                        icon={Icons[data?.goal.replace(' ', '')]}
                    />
                    <div id="info">
                        <img width="35px" height="35px" className='predictions-icons' src={Icons.Impressions} />
                        <div style={{ fontSize: 'small' }}>Impressions</div>
                        <div>
                            <animated.span>
                                {AnimateNumber(data?.impressions_margins[0])}
                            </animated.span>,
                            <animated.span>
                                {AnimateNumber(data?.impressions_margins[1])}
                            </animated.span>
                        </div>
                    </div>
                </div>
                <div style={{ marginBottom: 40 }}>
                    <div className="drawer-header" style={{ fontSize: "xxx-large", textAlign: "center" }}>
                        <animated.span>
                            {AnimateNumber(data?.result_margins[0])}
                        </animated.span>,
                        <animated.span>
                            {AnimateNumber(data?.result_margins[1])}
                        </animated.span>
                    </div>
                    <TypeWriterEffect
                        startDelay={40}
                        cursorColor="black"
                        text="These results represent the estimated number of customers you will acquire."
                        typeSpeed={60}
                        textStyle={{ fontSize: "medium" }}
                    />
                </div>
            </div>
            <div className="Section3">
                {/* <Zoom>
                    <Card title="Impressions" backgroundColor="#1c1839" style={{}}>
                        <h4>Expected Results Between</h4>
                        <h2>{data?.impressions_margins?.join(', ')}</h2>
                        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                            <img src={graphImage} />
                        </div>
                    </Card>
                </Zoom> */}
            </div>
        </div >
    );
}

export default StatisticsPage;