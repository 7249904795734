const DATA = [
    {
        title: "Choose your sector",
        label: "Sector",
        key: 'sector',
        options: [
            { value: 'Ecommerce & Retail', label: 'Ecommerce & Retail' },
            { value: 'B2B', label: 'B2B' },
            { value: 'Travel', label: 'Travel' },
            { value: 'Automotive', label: 'Automotive' },
        ]
    },
    {
        title: "What is your goal",
        label: "Goal",
        key: 'goal',
        options: [
            { value: 'post engagement', label: 'Interaction', description: 'Interaction marketing combines the best elements from filling out a form, reading text, watching a video, or listening to a recording.' },
            { value: 'Traffic', label: 'Traffic', description: 'Traffic refers, simply, to the number of visitors that visit your website or a specific webpage. Marketing efforts, many times, are focused on increasing the number of visits to help increase sales or qualified leads.' },
            { value: 'lead generation', label: 'Lead Generation', description: 'Lead generation is the initiation of consumer interest or enquiry into products or services of a business. A lead is the contact information and in some cases, demographic information of a customer who is interested in a specific product or service.' },
            { value: 'CONVERSIONS', label: 'Conversions', description: 'Conversions are the return on investment (ROI) for your spending. It’s absolutely imperative that you track this if you are to have any hope of gauging true performance of your campaign.' },
            { value: "pages likes", label: 'Likes', description:  'A like is a feature incorporated into social networks and other online platforms that allows the user to give positive feedback to any type of content, and thus connect with what interests them.' },
            { value: 'Messages', label: 'Messages', description: 'Text message marketing is the ongoing process of communicating business news, sales, promotions or other relevant information to your customers via SMS (short message service) text messages on their mobile devices. It is a type of digital marketing strategy that helps build brand awareness on a more personal level.' },
        ]
    },
    {
        title: "What is your budget",
        label: "Budget",
        key: 'budget',
        type: 'number',
        min: 10,
    },
    {
        title: "Specify the period of your compaign",
        dateRange: true,
        key: 'dateRange',
    }
]


export default DATA;